import React from "react";
import Banner from "./Banner/Banner";
import Footer from "./Footer/Footer";
const Homepage = () => {
  return (
    <>
    <div>
      <Banner />
      </div>
    <div>
    <Footer />
    </div>
      </>
  );
};

export default Homepage;
