import React from 'react';

const Oils = () => {
  return (
    <div>
      đây là trang Oils
    </div>
  );
}

export default Oils;
