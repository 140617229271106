import React from 'react';

const Account = () => {
  return (
    <div>
       đây là trang Account
    </div>
  );
}

export default Account;
