// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    box-sizing: border-box; 
    
}
`, "",{"version":3,"sources":["webpack://./src/component/container.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,cAAc;IACd,sBAAsB;;AAE1B","sourcesContent":[".container {\r\n    width: 100%;\r\n    max-width: 1440px;\r\n    margin: 0 auto;\r\n    box-sizing: border-box; \r\n    \r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
