import React from 'react';

const AboutPower5 = () => {
  return (
    <div>
      đây là trang About    
    </div>
  );
}

export default AboutPower5;
